<template>
    <div class="container-fluid img_backgrond">
        <div class="row holder">
            <div class="login-content">
                <div class="row">
                    <div class="col-sm-12 mt-3">
                        <h2 class="text-center">
                            <img src="./img/logo-quick.svg" class="logo" alt="Logo">
                        </h2>
                    </div>
                </div>
                <router-view />
            </div>
        </div>
        <img class="label" src="/images/label.png"/>
    </div>
</template>
<script>

export default {
    name: "auth-wrapper",
}
</script>
<style lang="scss" scoped>
    .holder {
        position: relative;
        top: calc(50vh - 390px / 2);
    }
    .forgot {
        color: #333;
        font-size: 15px;
        padding-bottom: 5px;

    }
    .label {
        position: absolute;
        left: 0px;
        bottom: 0px;
    }
    .has-error {
        color: red;
        input {
            border-color: red;
        }
    }
    .login-content {
        width: 300px;

        background-color: white !important;
        margin-bottom: 7%;
        box-shadow: 0 0 20px #ccc;
        background-size: 100% 100%;
        border-radius: 7px;
        margin: 0 auto;
        padding: 15px;
    }
    .img_backgrond{
        background: url("./img/background.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
    }
    label{
        font-size: 14px !important;
    }
    .logo {
        max-width: 100%;
        padding: 20px 16px;
    }
    ::-webkit-input-placeholder {
        font-size:14px;
    }
</style>
